import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { IndexContainer } from '../styled';

const IndexPage = () => {
  useEffect(() => {
    navigate('/app');
  }, []);
  return (
    <IndexContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="refresh" content="86400" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Collar Platform</title>
      </Helmet>
    </IndexContainer>
  );
};

export default IndexPage;
